﻿body {
  background-color: #e5d1cb;

  > .root {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
